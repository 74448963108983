import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { useUserContext } from "../../contexts/UserContext";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useRouteChange } from "../../hooks/useRouteChange";

interface NavbarProps {
  onLogout: () => void;
  setIsAddingCard: (value: boolean) => void;
  filterState: FilterState;
  setFilterState: (newFilterState: FilterState) => void;
}

const Navbar: React.FC<NavbarProps> = ({
  onLogout,
  setIsAddingCard,
  setFilterState,
  filterState,
}) => {
  const { user } = useUserContext();
  const [isHovered, setIsHovered] = useState(false);
  const pathname = useRouteChange();
  const [selectedOption, setSelectedOption] = useState(() => {
    if (pathname === "/bookmarked-ideas") {
      return "bookmark";
    } else if (pathname === "/claimed-ideas") {
      return "claim";
    } else if (pathname === "/") {
      return "all";
    }
  });

  const handleMouseEvents = (mouseEnter: boolean) => {
    setIsHovered(mouseEnter);
  };

  const onAddCard = () => {
    setIsAddingCard(true);
  };

  const handleCardsVisibility = (option: string) => {
    setFilterState({
      ...filterState,
      cardsVisibility: option,
    });
  };

  const [options, setOptions] = useState([
    { value: "all", label: "Home", isSelected: selectedOption === "all" },
    {
      value: "bookmark",
      label: "Bookmarked Ideas",
      isSelected: selectedOption === "bookmark",
    },
    {
      value: "claim",
      label: "Claimed Ideas",
      isSelected: selectedOption === "claim",
    },
    { value: "feature", label: "Suggest a feature", isSelected: false },
    { value: "roadmap", label: "Roadmap", isSelected: false },
    { value: "center", label: "Help Center", isSelected: false },
    { value: "review", label: "Review us", isSelected: false },
    { value: "logout", label: "Logout", isSelected: false },
  ]);

  const handleDropdownChange = (option: any) => {
    if (option.value === "logout") {
      onLogout();
    } else if (option.value === "feature") {
      setSelectedOption(selectedOption);
      setOptions([...options])
      window.open('https://ltdideas.featurebase.app/', '_blank',)
    } else if (option.value === "roadmap") {
      setSelectedOption(selectedOption);
      setOptions(options)
      window.open('https://ltdideas.featurebase.app/roadmap', '_blank',)
    } else if (option.value === "center") {
      setSelectedOption(selectedOption);
      setOptions(options)
      window.open('https://ltdideas.tawk.help/', '_blank',)
    } else if (option.value === "review") {
      setSelectedOption(selectedOption);
      setOptions(options)
      window.open('https://tally.so/r/wbkq9E', '_blank',)
    } else {
      // Update filter state and selected option
      handleCardsVisibility(option.value);
      setSelectedOption(option.value);
      setOptions(
        options.map((opt) => {
          return opt.value === option.value
            ? { ...opt, isSelected: true }
            : { ...opt, isSelected: false };
        })
      );
    }
  };

  return (
    <div className="flex justify-end w-full">
      <div className="button-container">
        {user?.isAdmin ? (
          <React.Fragment>
            <button
              className="add-button"
              onMouseEnter={() => handleMouseEvents(true)}
              onMouseLeave={() => handleMouseEvents(false)}
              onClick={onAddCard}
            >
              {isHovered ? <div className="tooltip">Add Card</div> : ""}
            </button>
          </React.Fragment>
        ) : (
          ""
        )}
        <div className="nav-menu">
          <Dropdown className="p-2"
            options={options.filter((opt) => !opt.isSelected)} // Filter out selected option
            onChange={handleDropdownChange}
            value={[...options].find((o) => o.value === selectedOption)}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
