import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import Card from "./Card/Card";
import "./Dashboard.css";
import "./global.css";
import CardPopup from "./Card/CardPopup";
import CardManagementPopup from "./Card/CardManagementPopup";
import { useUserContext } from "../contexts/UserContext";
import { useCardsContext } from "../contexts/CardsContext";
import CardsImport from "./Card/CardsImport";
import { makeAPIPath } from "../apiConfig";
import { useHistory } from "react-router-dom";
import { useRouteChange } from "../hooks/useRouteChange";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCardsState,
  setDisableFilterState,
  setFilterState,
  setIsAddingCard,
} from "../redux/filterReducer";

const queryClient = new QueryClient();
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <NewDashboard />
    </QueryClientProvider>
  );
}
const authToken: string | null = localStorage.getItem("authToken");

const filterClaimedCards = (data: any[]) => {
  return data.filter((item) => !item.isClaimed);
};

const fetchCards = async () => {
  let authToken: string | null = localStorage.getItem("authToken");
  if (authToken) {
    try {
      const response = await axios.get(makeAPIPath("/cards"), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const newCards = filterClaimedCards(response.data);
      return newCards;
    } catch (error) {
      console.error("Error fetching cards:", error);
      return error;
    }
  }
};

const fetchUserBookmarks = async () => {
  let authToken: string | null = localStorage.getItem("authToken");
  if (authToken) {
    try {
      const response = await axios.get(makeAPIPath("/user/bookmarked-cards"), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return response.data;
      // setBookmarkedCards(response.data);
    } catch (error) {
      console.error("Error fetching bookmark cards:", error);
    }
  }
};

const fetchUserClaimed = async () => {
  let authToken: string | null = localStorage.getItem("authToken");
  if (authToken) {
    try {
      const response = await axios.get(makeAPIPath("/user/claimed-cards"), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return response.data;
      // setClaimedCards(response.data);
    } catch (error) {
      console.error("Error fetching claimed cards:", error);
    }
  }
};

const NewDashboard = ({ }) => {
  const filterState = useSelector(
    (state: RootState) => state.filterData.filterState
  );
  const isAddingCard = useSelector(
    (state: RootState) => state.filterData.isAddingCard
  );
  const dispatch = useDispatch();

  const { user, setUser } = useUserContext();
  const { cards, setCards } = useCardsContext();
  const history = useHistory();
  const pathname = useRouteChange();

  const [constCards, setConstCards] = useState<Card[]>(cards);
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);
  const [claimedCards, setClaimedCards] = useState<Card[]>([]);
  const [bookmarkedCards, setBookmarkedCards] = useState<Card[]>([]);

  const [isEditing, setIsEditing] = useState(false);

  const prevSearchTerm = useRef(filterState.searchTerm);
  const prevCardsVisibility = useRef(filterState.cardsVisibility);
  const prevCards = useRef(cards);
  const setFilterValue = (res: any) => {
    let filteredCards = res;
    if (filterState.searchTerm) {
      filteredCards = filteredCards.filter((card: any) =>
        card.description
          ?.toLowerCase()
          .includes(filterState.searchTerm.toLowerCase())
      );
      prevSearchTerm.current = filterState.searchTerm;
    }
    if (filterState.selectedCategory[0]?.name.length > 0) {
      if (pathname === "/bookmarked-ideas") {
        if (filterState.searchTerm.length !== 0) {
          filteredCards = filteredCards.filter((card: any) =>
            card.description
              ?.toLowerCase()
              .includes(filterState.searchTerm.toLowerCase())
          );
        }
        filteredCards = filteredCards.filter((card: any) =>
          filterState.selectedCategory.some(
            (selectedCategory: any) => selectedCategory.name === card.category
          )
        );
      } else if (pathname === "/claimed-ideas") {
        if (filterState.searchTerm.length !== 0) {
          filteredCards = filteredCards.filter((card: any) =>
            card.description
              ?.toLowerCase()
              .includes(filterState.searchTerm.toLowerCase())
          );
        }
        filteredCards = filteredCards.filter((card: any) =>
          filterState.selectedCategory.some(
            (selectedCategory: any) => selectedCategory.name === card.category
          )
        );
      } else if (pathname === "/") {
        if (filterState.searchTerm.length !== 0) {
          filteredCards = filteredCards.filter((card: any) =>
            card.description
              ?.toLowerCase()
              .includes(filterState.searchTerm.toLowerCase())
          );
        }
        filteredCards = filteredCards.filter((card: any) =>
          filterState.selectedCategory.some(
            (selectedCategory: any) => selectedCategory.name === card.category
          )
        );
      }
    }
    if (filterState.selectedNiche[0]?.name.length > 0) {
      if (pathname === "/bookmarked-ideas") {
        if (filterState.searchTerm.length !== 0) {
          filteredCards = filteredCards.filter((card: any) =>
            card.description
              ?.toLowerCase()
              .includes(filterState.searchTerm.toLowerCase())
          );
        }
        filteredCards = filteredCards.filter((card: any) =>
          filterState.selectedNiche.some(
            (selectedNiche: any) => selectedNiche.name === card.niches
          )
        );
      } else if (pathname === "/claimed-ideas") {
        if (filterState.searchTerm.length !== 0) {
          filteredCards = filteredCards.filter((card: any) =>
            card.description
              ?.toLowerCase()
              .includes(filterState.searchTerm.toLowerCase())
          );
        }
        filteredCards = filteredCards.filter((card: any) =>
          filterState.selectedNiche.some(
            (selectedNiche: any) => selectedNiche.name === card.niches
          )
        );
      } else if (pathname === "/") {
        if (filterState.searchTerm.length !== 0) {
          filteredCards = filteredCards.filter((card: any) =>
            card.description
              ?.toLowerCase()
              .includes(filterState.searchTerm.toLowerCase())
          );
        }
        filteredCards = filteredCards.filter((card: any) =>
          filterState.selectedNiche.some(
            (selectedNiche: any) => selectedNiche.name === card.niches
          )
        );
      }
    }
    if (filterState.selectedSorting.SV !== "") {
      let sortedCards = filteredCards ? [...filteredCards] : [];
      if (filterState.selectedSorting.SV === "High to Low") {
        const sortByHighToLow = (a: any, b: any) => {
          return b.search_volume - a.search_volume;
        };
        filteredCards = sortedCards.sort(sortByHighToLow);
      }
      if (filterState.selectedSorting.SV === "Low to High") {
        const sortByLowToHigh = (a: any, b: any) => {
          return a.search_volume - b.search_volume;
        };
        filteredCards = sortedCards.sort(sortByLowToHigh);
      }
    }
    if (filterState.selectedSorting.CPC !== "") {
      let sortedCards = filteredCards ? [...filteredCards] : [];
      if (filterState.selectedSorting.CPC === "High to Low") {
        const sortByHighToLow = (a: any, b: any) => {
          return b.cpc - a.cpc;
        };
        filteredCards = sortedCards.sort(sortByHighToLow);
      }
      if (filterState.selectedSorting.CPC === "Low to High") {
        const sortByLowToHigh = (a: any, b: any) => {
          return a.cpc - b.cpc;
        };
        filteredCards = sortedCards.sort(sortByLowToHigh);
      }
    }
    setCards(filteredCards);
  };

  const cardData: any = useQuery({
    queryKey: ["cardData"],
    queryFn: () => {
      if (pathname === "/bookmarked-ideas") {
        return fetchUserBookmarks().then((res: any) => {
          setBookmarkedCards(res);
          setFilterValue(res);
          dispatch(setDisableFilterState(false));
          dispatch(setCardsState(res));
          return res;
        });
      } else if (pathname === "/claimed-ideas") {
        return fetchUserClaimed().then((res: any) => {
          setClaimedCards(res);
          setFilterValue(res);
          dispatch(setDisableFilterState(false));
          dispatch(setCardsState(res));
          return res;
        });
      } else if (pathname === "/") {
        return fetchCards().then((res: any) => {
          setCards(res);
          setConstCards(res);
          prevCards.current = res;
          setFilterValue(res);
          dispatch(setDisableFilterState(false));
          dispatch(setCardsState(res));
          return res;
        });
      }
    },
    staleTime: 10 * 1000,
  });

  let prevUser: Card;
  const handleCardClick = (card: Card) => {
    setSelectedCard(card);
  };

  const handleOverlayClosePopup = () => {
    setSelectedCard(null);
  };

  const handleClosePopup = () => {
    setSelectedCard(null);
  };

  const handleUpdateCard = (updatedCard: Card) => {
    const updatedCards = cards.map((c) =>
      c._id === updatedCard._id ? updatedCard : c
    );
    setCards(updatedCards);
    setSelectedCard(updatedCard);
  };

  useEffect(() => {
    if (pathname === "/bookmarked-ideas") {
      setFilterValue(bookmarkedCards);
    } else if (pathname === "/claimed-ideas") {
      setFilterValue(claimedCards);
    } else if (pathname === "/") {
      setFilterValue(constCards);
    }
  }, [filterState]);

  const fetchUser = async () => {
    let token = localStorage.getItem("authToken")
    if (token) {
      try {
        const response = await axios.get(makeAPIPath("/user/login/auth"), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (JSON.stringify(response.data) !== JSON.stringify(prevUser)) {
          setUser({ ...user, ...response.data });
          prevUser = response.data;

          if (pathname === "/bookmarked-ideas") {
            dispatch(
              setFilterState({
                ...filterState,
                cardsVisibility: "bookmark",
              })
            );
          } else if (pathname === "/claimed-ideas") {
            dispatch(
              setFilterState({
                ...filterState,
                cardsVisibility: "claim",
              })
            );
          } else if (pathname === "/") {
            dispatch(
              setFilterState({
                ...filterState,
                cardsVisibility: "all",
              })
            );
          }
        }
      } catch (error) {
        console.error("error", error);
      }
    } else {
      history.push("/login");
    }
  };

  useEffect(() => {
    if (
      JSON.stringify(prevCardsVisibility.current) !==
      JSON.stringify(filterState.cardsVisibility)
    ) {
      if (filterState.cardsVisibility === "bookmark") {
        prevCardsVisibility.current = "bookmark";
        history.push("/bookmarked-ideas");
      } else if (filterState.cardsVisibility === "claim") {
        prevCardsVisibility.current = "claim";
        history.push("/claimed-ideas");
      } else if (filterState.cardsVisibility === "all") {
        prevCardsVisibility.current = "all";
        history.push("/");
      }
    }
  }, [filterState.cardsVisibility]);

  useEffect(() => {
    dispatch(
      setFilterState({
        ...filterState,
        searchTerm: "",
        selectedCategory: [],
        selectedNiche: [],
        selectedSorting: [],
      })
    );
    if (pathname) {
      setConstCards([]);
      cardData.refetch();
    }
  }, [pathname]);

  const hasFetched = useRef(false);

  useEffect(() => {
    if (!hasFetched.current) {
      fetchUser();
      hasFetched.current = true;
    }
  }, []);
  const handleResetFilters = () => {
    dispatch(
      setFilterState({
        searchTerm: "",
        selectedCategory: [],
        selectedNiche: [],
        selectedSorting: {},
        cardsVisibility: filterState.cardsVisibility,
      })
    );
  };

  return (
    <>
      {cardData?.isLoading ? (
        <div className="dashboard">
          <div className="flex ml-[5%] loading-overlay justify-center">
            <div className="loading-spinner"></div>
          </div>
        </div>
      ) : cards?.length > 0 ? (
        <div className="flex flex-wrap w-full dashboard gap-4 m-[15px] overflow-y-scroll">
          {cards.length > 0 &&
            cards.map((card: any) => (
              <div className="card bg-[#1C2028] cursor-pointer min-h-48 w-[calc((100%-65px)/3)] flex justify-center flex-col border border-gray-800 rounded-lg p-6  shadow-lg transform transition-transform hover:-translate-y-1 hover:shadow-xl"
                key={card._id} onClick={() => handleCardClick(card)}>
                <div className="flex gap-2">
                  <div className="inline-block px-3 py-1 mb-3 text-white bg-gray-800 rounded-full card-category w-fit">
                    {card.niches}
                  </div>
                  <div className="inline-block px-3 py-1 mb-3 text-white bg-gray-800 rounded-full card-category w-fit">
                    {card.category}
                  </div>
                </div>
                <h3 className="text-[#E94363] font-bold text-base mb-2">{card.name}</h3>
                <p className="text-base text-white">{card.description}</p>
              </div>
            ))}
          {selectedCard && (
            <CardPopup
              selectedCard={selectedCard}
              fetchCards={cardData.refetch}
              setIsEditing={setIsEditing}
              handleOverlayClosePopup={handleOverlayClosePopup}
              handleClosePopup={handleClosePopup}
              bookmarkedCards={bookmarkedCards}
              setBookmarkedCards={setBookmarkedCards}
              claimedCards={claimedCards}
              setClaimedCards={setClaimedCards}
            />
          )}
          {selectedCard && isEditing && (
            <CardManagementPopup
              card_id={selectedCard?._id}
              onClose={() => setIsEditing(false)}
              onUpdateCard={handleUpdateCard}
            />
          )}
          {isAddingCard && (
            <CardsImport
              setIsAddingCard={(e) => dispatch(setIsAddingCard(e))}
            />
          )}
        </div>
      ) : (
        <div className="no-data-container">
          <div className="no-data-icon">🔍</div> {/* Use an appropriate icon */}
          <div className="text-white">No Data Found</div>
          <div
            className="px-4 py-2 mt-3 text-center text-white bg-[#E94363] rounded-md cursor-pointer hover:bg-red-700"
            onClick={handleResetFilters}
          >
            Reset All Filters
          </div>
        </div>
      )}
    </>
  );
};
