import React, { useState } from "react";
import * as XLSX from "xlsx";
import "./CardsImport.css";
import { useCardsContext } from "../../contexts/CardsContext";
import axios from "axios";
import { makeAPIPath } from "../../apiConfig";
import { useUserContext } from "../../contexts/UserContext";

interface CardsImportProps {
  setIsAddingCard: (value: boolean) => void;
}

const CardsImport: React.FC<CardsImportProps> = ({ setIsAddingCard }) => {
  const [jsonData, setJsonData] = useState<any | null>(null);
  const [startIndex, setStartIndex] = useState<number>(0);
  const { setCards } = useCardsContext();
  const { user } = useUserContext();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const CHUNK_SIZE = 50;

  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.name.endsWith(".xlsx")) {
      processFile(droppedFile);
    } else {
      alert("Please drop an XLSX file.");
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
      processFile(selectedFile);
    } else {
      alert("Please select an XLSX file.");
    }
  };

  const processFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        const workbook = XLSX.read(e.target.result, { type: "array" });
        const sheetName = "Main DB";
        const sheet = workbook.Sheets[sheetName];
        const sheetData: any = XLSX.utils.sheet_to_json(sheet);

        const columnMapping: { [key: string]: keyof CardImport } = {
          Name: "name",
          Description: "description",
          Category: "category",
          "Pain point": "problem_it_solves",
          "What it does (The solution)": "solution",
          "Pricing Structure": "pricing_structure",
          "Target Audience": "target_audience",
          Keyword: "keyword",
          "Search Volume": "search_volume",
          CPC: "cpc",
        };

        const tmpJsonData = Object.values(sheetData)
          .map((row: any, index: number) => {
            if (index < startIndex) {
              return null; // Skip rows before the startIndex
            }

            const card: Partial<CardImport> = {};

            Object.keys(row).forEach((oldColumnName) => {
              const newColumnName =
                columnMapping[oldColumnName] || oldColumnName;
              card[newColumnName as keyof CardImport] = row[oldColumnName];
            });

            if (!card.price) {
              card.price = "15";
            }

            if (!card.isClaimed) {
              card.isClaimed = false;
            }

            return card as CardImport;
          })
          .filter((card: any) => card !== null); // Remove null entries
        setJsonData(tmpJsonData);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleOnUpload = async () => {
    try {
      const totalChunks = Math.ceil(jsonData.length / CHUNK_SIZE);

      for (let i = 0; i < totalChunks; i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, jsonData.length);
        const chunk = jsonData.slice(start, end);

        const response = await axios.post(makeAPIPath("/cards"), chunk, {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / (progressEvent.total ?? 1)) * 100
            );
            setUploadProgress(progress);
          },
        });

        setCards((prevCards) => [...prevCards, ...response.data]);
      }

      setUploadProgress(0); // Reset progress after upload is complete
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOnClose = () => {
    setJsonData(null);
    setIsAddingCard(false);
  };

  return (
    <div className="cards-import-popup">
      <div
        className="cards-import-file input-container"
        onDrop={handleFileDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <p className="cards-import-file input-container-drag-drop">
          Drag and drop an XLSX file here or click to browse.
        </p>
        <input type="file" onChange={handleFileInputChange} accept=".xlsx" />
      </div>
      <div className="start-index-container-input-container">
        <label>Starting Index:</label>
        <input
          type="number"
          value={startIndex}
          onChange={(e) => setStartIndex(parseInt(e.target.value))}
          min="0"
          step="1"
        />
      </div>
      {uploadProgress !== 0 && (
        <div className="progress-container">
          <div
            className="progress-bar"
            style={{ width: `${uploadProgress}%` }}
          ></div>
        </div>
      )}
      <div className="button-container">
        <button
          className="upload-button"
          onClick={handleOnUpload}
          disabled={!jsonData}
        >
          Upload
        </button>
        <button className="close-button" onClick={handleOnClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default CardsImport;
