import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  osName,
  osVersion,
  browserName,
} from "react-device-detect";
import { useUserContext } from "../../contexts/UserContext";
import axios from "axios";
import { makeAPIPath } from "../../apiConfig";

const Login: React.FC = () => {
  const { setUser } = useUserContext();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [license, setLicense] = useState("");
  const [loginError, setLoginError] = useState("");
  const [showPassword, setShowPassword] = useState(true);

  const validateEmail = (email: string) => {
    const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post(makeAPIPath("/user/login"), {
        email: email,
        license: license,
      });

      try {
        const licenseActivationResponse = await axios.post(
          "https://api.lemonsqueezy.com/v1/licenses/activate",
          new URLSearchParams({
            license_key: license,
            instance_name: `${osName} ${osVersion} ${browserName}`,
          }),
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        const activationData = licenseActivationResponse?.data;
        const activationInstance = activationData.instance;

        setUser(response.data);
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("activationIDLS", activationInstance.id);
        localStorage.setItem("activationNameLS", activationInstance.name);
        localStorage.setItem("licenseKeyLS", activationData.license_key.key);
        history.push("/");
      } catch (errorResponse) {
        //@ts-ignore
        const activationData = errorResponse?.response?.data;
        const isActivationError = activationData?.error;
        const licenseData = activationData?.license_key;

        setLoginError(
          `${isActivationError} You've already activated your license ${licenseData.activation_usage} times.\n\nWe only allow ${licenseData.activation_limit} activations at a time.\n\nPlease deactivate your old activations in your LemonSqueezy Dashboard.`
        );
      }
    } catch (error) {
      setLoginError("Invalid email address or password:");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setLoginError("Invalid email address");
      setTimeout(() => {
        setLoginError("");
      }, 5000);
      return;
    }
    setLoginError("");
    handleLogin(e);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const licenseKey = urlParams.get("license_key");
    const emailKey = urlParams.get("email");
    if (licenseKey !== null && emailKey !== null) {
      setLicense(licenseKey);
      setEmail(emailKey);
      const cleanUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, cleanUrl);
    }
  }, []);

  return (
    <section className="relative h-[100vh] bg-gray-900 grid place-items-center">
      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative max-w-md mx-auto lg:max-w-xl">
          <div className="absolute inset-x-1.5 top-8 -inset-y-4">
            <div
              className="w-full h-full mx-auto rotate-180 rounded-3xl opacity-90 blur-xl filter"
              style={{
                background:
                  "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
              }}
            ></div>
          </div>

          <div className="relative overflow-hidden bg-white rounded-2xl lg:rounded-3xl">
            <div className="px-6 py-7 sm:px-12 sm:py-10">
              <div className="text-center">
                <h1 className="text-3xl font-bold text-gray-900 font-pj sm:text-4xl xl:text-5xl">
                  Login to LTD Ideas
                </h1>
                <p className="mt-6 text-lg font-normal text-gray-600 font-pj">
                  Sign in to LTD Ideas today & start growing your business
                </p>
              </div>

              <form onSubmit={handleSubmit} className="mt-8 ">
                <div className="space-y-4">
                  <div>
                    <label
                      htmlFor=""
                      className="text-base font-medium text-gray-900 font-pj"
                    >
                      Email
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="email"
                        value={email}
                        placeholder="Enter E-Mail"
                        onChange={(e) => setEmail(e.target.value)}
                        className="block w-full px-4 py-4 text-base text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-800 focus:outline-none focus:border-gray-600 focus:bg-gray-50 caret-gray-600 rounded-xl font-pj"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor=""
                      className="text-base font-medium text-gray-900 font-pj"
                    >
                      License Key
                    </label>
                    <div className="mt-2.5 relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        value={license}
                        placeholder="Enter License Key"
                        onChange={(e) => setLicense(e.target.value)}
                        className="block w-full px-4 py-4 text-base text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-800 focus:outline-none focus:border-gray-600 focus:bg-gray-50 caret-gray-600 rounded-xl font-pj"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-y-0 right-0 px-3 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-800 focus:outline-none focus:border-gray-600 focus:bg-gray-50 caret-gray-600 rounded-r-xl focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
                      >
                        {showPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                    {!license && license.length === 0 && (
                      <p className="mt-2 ml-2 text-xs">
                        Already a customer?{" "}
                        <a
                          className="text-blue-500 underline"
                          href="https://app.lemonsqueezy.com/my-orders/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Find your license key here
                        </a>
                      </p>
                    )}
                  </div>

                  {loginError && (
                    <div className="mt-2 mb-2 ml-1 text-sm text-red-500 whitespace-pre-wrap">
                      {loginError}
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  className="inline-flex items-center justify-center w-full px-8 py-4 mt-8 text-base font-bold text-white transition-all duration-200 bg-gray-800 border border-transparent rounded-xl hover:bg-gray-900 focus:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 font-pj"
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
