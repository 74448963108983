import React, { useState } from "react";
import "./SignUp.css";
import "../global.css";
import { makeAPIPath } from "../../apiConfig";
import axios from "axios";

const SignUp: React.FC = () => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
  });
  const [emailError, setEmailError] = useState("");
  const [emailBorderColor, setEmailBorderColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(makeAPIPath("/user"), userData);
      window.location.href = response.data.data.selfCheckoutUrl;
    } catch (error) {
      setIsLoading(false);
      console.error("Error signing up:", error);
    }
  };

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateEmail(userData.email)) {
      setEmailError("Invalid email address");
      setTimeout(() => {
        setEmailError("");
      }, 5000);
      setEmailBorderColor("red");
      setTimeout(() => {
        setEmailBorderColor("");
      }, 5000);
      return;
    }

    setEmailError("");
    handleSignUp(e);
  };

  return (
    <div className="signup-container">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <div className="signup-input-container">
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={userData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            style={{ borderColor: emailBorderColor }}
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
            required
          />
          {emailError && <div className="error-message">{emailError}</div>}
        </div>
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
};

export default SignUp;
