import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface filterState {
  filterState: any;
  isAddingCard: boolean;
  disableFilterState: boolean;
  cards: any;
}

const initialState: filterState = {
  filterState: {
    searchTerm: "",
    selectedCategory: [],
    selectedNiche: [],
    selectedSorting: {
      SV: "",
      CPC: "",
    },
  },
  isAddingCard: false,
  disableFilterState: true,
  cards: [],
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<any>) => {
      state.filterState = action.payload;
    },
    setIsAddingCard: (state, action: PayloadAction<any>) => {
      state.isAddingCard = action.payload;
    },
    setDisableFilterState: (state, action: PayloadAction<any>) => {
      state.disableFilterState = action.payload;
    },
    setCardsState: (state, action: PayloadAction<any>) => {
      state.cards = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setFilterState, setIsAddingCard, setDisableFilterState, setCardsState } =
  filterSlice.actions;

export default filterSlice.reducer;
