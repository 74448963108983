import React, { useEffect, useState } from "react";
import "./CardPopup.css";
import "../global.css";
import { FaRegBookmark, FaBookmark } from "react-icons/fa";
import { useUserContext } from "../../contexts/UserContext";
import { useCardsContext } from "../../contexts/CardsContext";
import { makeAPIPath } from "../../apiConfig";
import axios from "axios";
import { toast } from "react-toastify";

const CardPopup: React.FC<CardPopupProps> = ({
  selectedCard,
  fetchCards,
  handleOverlayClosePopup,
  handleClosePopup,
  bookmarkedCards,
  setBookmarkedCards,
}) => {
  const { user, setUser } = useUserContext();
  const { cards, removeCard } = useCardsContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [overlayShow, setOverlayShow] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState("");

  useEffect(() => {
    // Check if card is already bookmarked
    if (user?.bookmarkedCards?.includes(selectedCard._id))
      setIsBookmarked(true);
  }, [user?.bookmarkedCards, selectedCard._id]);

  const handleBookmarkToggle = async () => {
    setIsBookmarked(!isBookmarked);
    try {
      if (!isBookmarked) {
        const response = await axios.get(
          makeAPIPath(`/user/bookmark-card/${selectedCard._id}`),
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const bookmarkedCard = cards?.filter(
          (card: Card) => selectedCard._id === card._id
        );
        setUser({ ...user, ...response.data });
        setBookmarkedCards([...bookmarkedCards, ...bookmarkedCard]);
        toast.success("Bookmark added!");
      } else {
        const response = await axios.delete(
          makeAPIPath(`/user/bookmark-card/${selectedCard._id}`),
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );
        setUser({ ...user, ...response.data });
        setBookmarkedCards(
          bookmarkedCards.filter((item: any) => item._id !== selectedCard._id)
        );
        toast.success("Bookmark removed!");
      }
    } catch (error) {
      console.log("Error bookmarking card:", error);
    }
  };

  const handleOnClaim = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        makeAPIPath(`/user/claim-card/${selectedCard._id}`),
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (response.data.selfCheckoutUrl) {
        setCheckoutUrl(response.data.selfCheckoutUrl);
        setOverlayShow(true);
      } else {
        handleClosePopup();
        fetchCards();
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error claiming card:", error);
    }
  };

  const handleOnDelete = async () => {
    const response = await axios.delete(
      makeAPIPath(`/cards/delete/${selectedCard._id}`),
      {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      }
    );
    handleClosePopup();
    fetchCards();
  };

  const formatCardContent = (text: string) => {
    let points = text
      .trim()
      .split("\n")
      .filter((point) => point.trim() !== "");

    return (
      <ul className="list-disc">
        {points.map((point, index) => (
          <div key={index} className="pl-8">
            <li>{point.trim().replace("-", "")}</li>
            <br />
          </div>
        ))}
      </ul>
    );
  };
  return (
    <div className="card-popup">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      {overlayShow ? (
        <div className="lemon-overlay">
          <div className="lemon-overlay-content">
            <iframe
              src={checkoutUrl}
              title="Checkout"
              onLoad={() => setIsLoading(false)}
              style={{
                width: "1000px",
                height: "800px",
                border: "none",
              }}
            ></iframe>
          </div>
          {!isLoading && (
            <button
              className="card-btn-close-button"
              style={{ color: "red" }}
              onClick={handleOverlayClosePopup}
            >
              &times;
            </button>
          )}
        </div>
      ) : (
        <div
          className="card-popup-content"
          style={{ display: isLoading ? "none" : "flex" }}
        >
          <div className="card-btn">
            {isBookmarked ? (
              <FaBookmark
                style={{ color: "white", fontSize: "24px", cursor: "pointer" }}
                onClick={handleBookmarkToggle}
              />
            ) : (
              <FaRegBookmark
                style={{ color: "white", fontSize: "24px", cursor: "pointer" }}
                onClick={handleBookmarkToggle}
              />
            )}
            <button
              className="card-btn-close-button"
              onClick={handleClosePopup}
            >
              &times;
            </button>
          </div>
          <div className="card-details">
            <div className="card-details-heading">
              <h1 className="mb-4 text-2xl font-bold ">{selectedCard.name}</h1>
              <h3>{selectedCard.description}</h3>
              <div className="my-4 cards-category-container">
                {selectedCard.category}
              </div>
            </div>
            <div className="card-details content-container">
              <div
                className="py-2 mt-2 card-details card-details-content"
                style={{ display: "flex" }}
              >
                <div className="cards-details-h2">
                  <h2>CPC</h2>
                  <span className="cards-details-span">:</span>
                </div>
                <div
                  className="cards-category-container"
                  style={{ marginLeft: "10px" }}
                >
                  ${selectedCard.cpc}
                </div>
              </div>
              <div
                className="py-2 card-details card-details-content"
                style={{ display: "flex" }}
              >
                <div className="cards-details-h2">
                  <h2>Search Volume</h2>
                  <span className="cards-details-span">:</span>
                </div>
                <div
                  className="cards-category-container"
                  style={{ marginLeft: "10px" }}
                >
                  {selectedCard.search_volume}
                </div>
              </div>
              <div
                className="py-2 card-details card-details-content"
                style={{ display: "flex" }}
              >
                <div className="cards-details-h2">
                  <h2>Keyword</h2>
                  <span className="cards-details-span">:</span>
                </div>
                <div
                  className="cards-category-container"
                  style={{ marginLeft: "10px" }}
                >
                  {selectedCard.keyword}
                </div>
              </div>
              <div className="card-details card-details-content">
                <h2 className="py-2 text-xl font-bold">Pain Points</h2>
                {formatCardContent(selectedCard.problem_it_solves)}
              </div>
              <div className="card-details card-details-content">
                <h2 className="py-2 text-xl font-bold">What it does</h2>
                {formatCardContent(selectedCard.solution)}
              </div>
              <div className="card-details card-details-content">
                <h2 className="py-2 text-xl font-bold">Pricing structure</h2>
                {formatCardContent(selectedCard.pricing_structure)}
              </div>
              <div className="card-details card-details-content">
                <h2 className="py-2 text-xl font-bold">Target Audience</h2>
                {formatCardContent(selectedCard.target_audience)}
              </div>
            </div>
          </div>
          <div className="my-4 card-button-container">
            {user?.isAdmin ? (
              <React.Fragment>
                {/* <button className="card-btn-edit-button" onClick={handleOnEdit}>
                    Edit
                  </button> */}
                <button
                  className="card-btn-delete-button"
                  onClick={handleOnDelete}
                >
                  Delete
                </button>
              </React.Fragment>
            ) : (
              ""
            )}
            {!selectedCard.isClaimed && (
              <button className="card-btn-claim-button" onClick={handleOnClaim}>
                {/* Claim this idea! - ${selectedCard.price} */}
                Claim this idea! - $10
              </button>
            )}
          </div>
          {!selectedCard.isClaimed && (
            <div className="p-2 card-claim-tooltip">
              <ul>
                <h3>How Claim Idea Works?</h3>
                <li>Pay $10 and claim the idea</li>
                <li>
                  This idea <b>will not be visible</b> to other users
                </li>
                <li>
                  Later revisit all your claimed ideas under your profile.
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CardPopup;
