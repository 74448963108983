import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface Props {
  filterState: FilterState;
  setFilterState: (newFilterState: FilterState) => void;
}

const niches = [
  "SaaS",
  "Blogging",
  "Startup",
  "AI",
  "Tech",
  "Writing",
  "Copywriting",
  "Travel",
  "Design",
  "Freelancing",
  "Sales",
  "No Code",
  "Online Income",
  "eCommerce",
  "SEO",
  "Facebook Ads",
  "Email Marketing",
  "Affiliate Marketing",
  "Dropshipping",
  "Nonprofit",
  "Social Media",
  "Google Ads",
  "Content Marketing",
  "Influencer Marketing",
  "Credit Cards",
  "Personal Finance",
  "Stock Investing",
  "Stock Trading",
  "Personal Development",
  "Love/Relationship",
  "Meditation",
  "Yoga",
  "Gym/Workout",
  "Law of Attraction/Manifestation",
  "Job Search",
  "Mental Model",
  "Parenting",
  "VC Funding",
];

const categories = ["Database", "Swipe File", "Directory", "Playbook", "Templates", "Toolkit", "Resources", "Workshop", "Videos", "Case Studies", "Blueprint", "Models", "Program", "Network", "Scripts", "Forum", "Prompts"]

const sorting = ["High to Low", "Low to High"];

const Sidebar: React.FC<Props> = ({ filterState, setFilterState }) => {
  const disableFilter = useSelector((state: RootState) => state.filterData.disableFilterState)
  const cards:any = useSelector((state: RootState) => state.filterData.cards)

  let nichesOption = cards?.length > 0 ? Array.from(new Set(cards.filter((data:any) => data.niches).map((data:any) => data.niches))) : []
  let categoryOption = cards?.length > 0 ? Array.from(new Set(cards.filter((data:any) => data.category).map((data:any) => data.category))) : []

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterState({ ...filterState, searchTerm: event.target.value });
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setFilterState({
      ...filterState,
      selectedCategory: [{ name: event.target.value, isSelected: true }],
    });
  };

  const handleNicheChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setFilterState({
      ...filterState,
      selectedNiche: [{ name: event.target.value, isSelected: true }],
    });
  };

  const handleSortingChange = (
    key: string,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (key === "CPC") {
      setFilterState({
        ...filterState,
        selectedSorting: {
          ...filterState.selectedSorting,
          "CPC": event.target.value,
          "SV": "",
        },
      });
    } else if (key === "SV") {
      setFilterState({
        ...filterState,
        selectedSorting: {
          ...filterState.selectedSorting,
          "SV": event.target.value,
          "CPC": "",
        },
      });
    } else {
      setFilterState({
        ...filterState,
        selectedSorting: {
          ...filterState.selectedSorting,
          [key]: event.target.value,
        },
      });
    }

  };

  const handleResetFilters = () => {
    setFilterState({
      searchTerm: "",
      selectedCategory: [],
      selectedNiche: [],
      selectedSorting: {},
      cardsVisibility: filterState.cardsVisibility,
    });
  };

  return (
    <div
      className="h-screen min-w-[22%] bg-gray-900 p-6"
      style={{ zIndex: 9997 }}
    >
      <a href="/">
        <img
          height={100}
          width={200}
          src="https://res.cloudinary.com/dgnbzbm19/image/upload/v1717248985/uelklckxx8798newexn4.png"
          alt="Logo"
        />
      </a>
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search"
          value={filterState.searchTerm}
          onChange={handleSearchChange}
          className="w-full px-4 py-2 mt-4 text-white bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          disabled={disableFilter}
        />
      </div>
      <div className="space-y-6">
        <div className="space-y-2">
          <h3 className="text-white">Filter by Niche</h3>
          <select
            value={
              filterState.selectedNiche.length > 0
                ? filterState.selectedNiche[0].name
                : ""
            }
            onChange={handleNicheChange}
            className="w-full px-4 py-2 text-white bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={disableFilter}
          >
            <option value="">Select a niche</option>
            {nichesOption.map((niche:any) => (
              <option key={niche} value={niche}>
                {niche}
              </option>
            ))}
          </select>
        </div>
        <div className="space-y-2">
          <h3 className="text-white">Filter by Category</h3>
          <select
            value={
              filterState.selectedCategory.length > 0
                ? filterState.selectedCategory[0].name
                : ""
            }
            onChange={handleCategoryChange}
            className="w-full px-4 py-2 text-white bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={disableFilter}
          >
            <option value="">Select a category</option>
            {categoryOption.map((category:any) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <hr className="my-4 border-gray-700" />
        <div className="space-y-2">
          <h3 className="text-white">Sort by Search Volume</h3>
          <select
            value={filterState.selectedSorting.SV || ""}
            onChange={(e) => handleSortingChange("SV", e)}
            className="w-full px-4 py-2 text-white bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={disableFilter}
          >
            <option value="">Select a sort order</option>
            {sorting.map((sort) => (
              <option key={sort} value={sort}>
                {sort}
              </option>
            ))}
          </select>
        </div>
        <hr className="my-4 border-gray-700" />
        <div className="space-y-2">
          <h3 className="text-white">Sort by CPC</h3>
          <select
            value={filterState.selectedSorting.CPC || ""}
            onChange={(e) => handleSortingChange("CPC", e)}
            className="w-full px-4 py-2 text-white bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={disableFilter}
          >
            <option value="">Select a sort order</option>
            {sorting.map((sort) => (
              <option key={sort} value={sort}>
                {sort}
              </option>
            ))}
          </select>
        </div>
        <hr className="my-4 border-gray-700" />
        <div
          className="px-4 py-2 text-center text-white bg-[#E94363] rounded-md cursor-pointer hover:bg-red-700"
          onClick={handleResetFilters}
        >
          Reset All Filters
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
