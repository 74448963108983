import React, { useEffect, useState } from "react";
import { useCardsContext } from "../../contexts/CardsContext";
import "./CardManagementPopup.css";

const CardManagementPopup: React.FC<CardManagementPopupProps> = ({
  card_id,
  onClose,
  onUpdateCard,
}) => {
  const { addCard, updateCard, cards } = useCardsContext();
  const [card, setCard] = useState<Card>({
    _id: "",
    name: "",
    description: "",
    problem_it_solves: "",
    solution: "",
    category: "",
    target_audience: "",
    keyword: "",
    cpc: -1,
    search_volume: -1,
    tags: [],
    price: "",
    pricing_structure: "",
    isClaimed: false,
  });

  useEffect(() => {
    if (card_id !== null) {
      const existingCard = cards.find((c) => c._id === card_id);
      if (existingCard) {
        setCard(existingCard);
      }
    }
  }, [card_id, cards]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCard({ ...card, [name]: value });
  };

  const handleSave = () => {
    if (card_id === null) {
      //! send AddCard to the backend and get the card obj in response
      addCard({ ...card });
    } else {
      updateCard(card_id, card);
      onUpdateCard(card);
    }
    onClose();
  };

  return (
    <div className="card-management-popup">
      <div className="card-management-popup-content">
        <div className="card-management-popup-content-field">
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={card.name}
            onChange={handleChange}
          />
        </div>
        <div className="card-management-popup-content-field">
          <label>Description:</label>
          <input
            type="text"
            name="description"
            value={card.description}
            onChange={handleChange}
          />
        </div>
        <div className="card-management-popup-content-field">
          <label>Problem it solves:</label>
          <input
            type="text"
            name="problem_it_solves"
            value={card.problem_it_solves}
            onChange={handleChange}
          />
        </div>
        <div className="card-management-popup-content-field">
          <label>Solution:</label>
          <input
            type="text"
            name="solution"
            value={card.solution}
            onChange={handleChange}
          />
        </div>
        <div className="card-management-popup-content-field">
          <label>Category:</label>
          <input
            type="text"
            name="category"
            value={card.category}
            onChange={handleChange}
          />
        </div>
        <div className="card-management-popup-content-field">
          <label>Target Audience:</label>
          <input
            type="text"
            name="target_audience"
            value={card.target_audience}
            onChange={handleChange}
          />
        </div>
        <div className="card-management-popup-content-field">
          <label>Tags:</label>
          <input
            type="text"
            name="tags"
            value={card.tags}
            onChange={handleChange}
          />
        </div>
        <div className="card-management-popup-content-field">
          <label>Pricing Structure:</label>
          <input
            type="text"
            name="pricing_structure"
            value={card.pricing_structure}
            onChange={handleChange}
          />
        </div>
        <div className="card-mgt-button-container">
          <button className="save-button" onClick={handleSave}>
            Save
          </button>
          <button className="close-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardManagementPopup;
