import React, { createContext, useContext, useState, ReactNode } from "react";

interface CardsContextType {
  cards: Card[];
  setCards: React.Dispatch<React.SetStateAction<Card[]>>;
  addCard: (card: Card) => void;
  removeCard: (id: string) => void;
  updateCard: (id: string, updatedCard: Card) => void;
}

const CardsContext = createContext<CardsContextType | null>(null);

export const useCardsContext = () => {
  const context = useContext(CardsContext);
  if (!context) {
    throw new Error(
      "useCardsContext must be used within a CardsContextProvider"
    );
  }
  return context;
};

export const CardsContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cards, setCards] = useState<Card[]>([]);

  const addCard = (card: Card) => {
    setCards([...cards, card]);
  };

  const removeCard = (id: string) => {
    setCards(cards?.filter((card) => card._id !== id));
  };

  const updateCard = (id: string, updatedCard: Card) => {
    setCards(cards.map((card) => (card._id === id ? updatedCard : card)));
  };

  return (
    <CardsContext.Provider
      value={{ cards, setCards, addCard, removeCard, updateCard }}
    >
      {children}
    </CardsContext.Provider>
  );
};
