import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const useRouteChange = () => {
  const [pathname, setPathname] = useState(window.location.pathname);
  const history = useHistory();

  useEffect(() => {
    const historyListener = history.listen((location) => {
      setPathname(location.pathname);
    });

    return historyListener;
  }, [history]);

  return pathname;
};
