import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import axios from "axios";

import Login from "./components/User/Login";
import SignUp from "./components/User/SignUp";
import Sidebar from "./components/Layout/Sidebar";
import Navbar from "./components/Layout/Navbar";
import "./App.css";
import { UserContextProvider } from "./contexts/UserContext";
import { CardsContextProvider } from "./contexts/CardsContext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./components/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { setFilterState, setIsAddingCard } from "./redux/filterReducer";

const App: React.FC = () => {
  const filterState = useSelector((state: RootState) => state.filterData.filterState)
  const dispatch = useDispatch()

  const handleLogout = async () => {
    try {
      const licenseKey = localStorage.getItem("licenseKeyLS");
      const activationId = localStorage.getItem("activationIDLS");
      if (licenseKey && activationId) {
        await axios.post(
          "https://api.lemonsqueezy.com/v1/licenses/deactivate",
          new URLSearchParams({
            license_key: licenseKey,
            instance_id: activationId,
          }),
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
      }
    } catch (error) { }
    localStorage.removeItem("authToken");
    localStorage.removeItem("licenseKeyLS");
    localStorage.removeItem("activationNameLS");
    localStorage.removeItem("activationIDLS");
    window.location.href = "/login";
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
      />
      <UserContextProvider>
        <CardsContextProvider>
          <Router>
            <div className="App">
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/signup">
                  <SignUp />
                </Route>

                <Route path="/">
                  <div className="app-layout">
                    <Sidebar
                      filterState={filterState}
                      setFilterState={(val) => dispatch(setFilterState(val))}
                    />
                    <div className="flex flex-col w-full">
                      <Navbar
                        onLogout={handleLogout}
                        setIsAddingCard={(val) => dispatch(setIsAddingCard(val))}
                        filterState={filterState}
                        setFilterState={(val) => dispatch(setFilterState(val))}
                      />
                      <Dashboard />
                    </div>
                  </div>
                </Route>
                <Route path="/bookmarked-ideas">
                  <div className="app-layout">
                    <Navbar
                      onLogout={handleLogout}
                      setIsAddingCard={(val) => dispatch(setIsAddingCard(val))}
                      filterState={filterState}
                      setFilterState={(val) => dispatch(setFilterState(val))}
                    />
                    <Sidebar
                      filterState={filterState}
                      setFilterState={(val) => dispatch(setFilterState(val))}
                    />
                    <Dashboard />
                  </div>
                </Route>
                <Route path="/claimed-ideas">
                  <div className="app-layout">
                    <Navbar
                      onLogout={handleLogout}
                      setIsAddingCard={(val) => dispatch(setIsAddingCard(val))}
                      filterState={filterState}
                      setFilterState={(val) => dispatch(setFilterState(val))}
                    />
                    <Sidebar
                      filterState={filterState}
                      setFilterState={(val) => dispatch(setFilterState(val))}
                    />
                    <Dashboard />
                  </div>
                </Route>
              </Switch>
            </div>
          </Router>
        </CardsContextProvider>
      </UserContextProvider>
    </React.Fragment>
  );
};

export default App;
